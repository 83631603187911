const msgSuccessRegistration = () => (
  `Open the link sent to your email to activate this account and complete
your registration`)

const msgSuccessValidation = () => (
  `Your account is validated`)

const msgSuccessReset = () => (
  `Your password has been reset.`)

const msgSuccessProfileUpdate = () => (
  `Your profile has been updated.`)

const msgSuccessForgotPassword = () => (
  `Open the link sent to your email to reset the password.`)

const msgSuccessDevice = () => (
  `Login is approved for your device.`)

export {
  msgSuccessForgotPassword,
  msgSuccessRegistration,
  msgSuccessValidation,
  msgSuccessDevice,
  msgSuccessReset,
  msgSuccessProfileUpdate
}
