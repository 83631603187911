export const toObject = list => Object.freeze(
  list.reduce((p, val) => Object.assign(p, { [val]: val }), {})
)

const enumPageTypeRegister = '/'
const enumPageTypeValidate = '/validate'
const enumPageTypeForgot = '/forgot'
const enumPageTypeReset = '/reset-password'
const enumPageTypeLogin = '/login'
const enumPageTypeProfile = '/profile'
const enumPageTypeProfileUpdate = '/profile-update'
const enumPageTypeLogout = '/logout'
const enumPageTypeDevice = '/device'
const enumPageTypes = toObject([
  enumPageTypeRegister,
  enumPageTypeValidate,
  enumPageTypeProfile,
  enumPageTypeProfileUpdate,
  enumPageTypeForgot,
  enumPageTypeLogout,
  enumPageTypeDevice,
  enumPageTypeReset,
  enumPageTypeLogin,
  enumPageTypeLogout
])

const enumPageTypesReqSessInactive = toObject([
  enumPageTypeLogin
])

const enumPageTypesReqSessActive = toObject([
  enumPageTypeProfileUpdate,
  enumPageTypeProfile,
  enumPageTypeDevice
])

const enumInputToken = 'token'
const enumInputDisplayName = 'display_name'
const enumInputEmail = 'email'
const enumInputPassword = 'password'
const enumInputConfirmPassword = 'confirm password'
const enumInputClientId = 'client_id'
const enumInputUserCode = 'user_code'
const enumInputTOSAgreement = 'tos_is_agree'
const enumInputDateOfBirth = 'date_of_birth_ISO8601'
const enumSessRefreshToken = 'refresh_token'
const enumSessDisplayName = 'display_name'
const enumSessTimeStarted = 'session_started'
const enumSessExpiresIn = 'session_expires'

export {
  enumPageTypeRegister,
  enumPageTypeValidate,
  enumPageTypeProfile,
  enumPageTypeProfileUpdate,
  enumPageTypeForgot,
  enumPageTypeReset,
  enumPageTypeLogin,
  enumPageTypeLogout,
  enumPageTypeDevice,
  enumPageTypes,
  enumPageTypesReqSessActive,
  enumPageTypesReqSessInactive,

  enumInputToken,
  enumInputDisplayName,
  enumInputEmail,
  enumInputPassword,
  enumInputConfirmPassword,
  enumInputTOSAgreement,
  enumInputClientId,
  enumInputUserCode,
  enumInputDateOfBirth,

  enumSessTimeStarted,
  enumSessRefreshToken,
  enumSessDisplayName,
  enumSessExpiresIn
}
