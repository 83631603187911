import isdev from './isdev.js'

const openIdConfigDevURI =
  'https://vr-360-content-main.s3.us-west-2.amazonaws.com/.well-known/openid-config-dev'
const openIdConfigProdURI =
  'https://vr-360-content-main.s3.us-west-2.amazonaws.com/.well-known/openid-config'

const params = new window.URLSearchParams(window.location.search)

const oauthDetailsPublicVASOO = {
  openid_uri: isdev ? openIdConfigDevURI : openIdConfigProdURI,
  client_id: 'vasoo-AofQQNfMmMMvhsGjrOSKQ'
}

const oauthDetailsPublicICONIC = {
  openid_uri: isdev ? openIdConfigDevURI : openIdConfigProdURI,
  client_id: 'iconic-BRDkNKvA7x5Ci4nJJve9Y'
}

const oauthDetailsPublicZEE5 = {
  openid_uri: isdev ? openIdConfigDevURI : openIdConfigProdURI,
  client_id: 'zee5-cundemCiuT6NNEuqlI8Ln'
}

const oauthDetailsPublicEnv = ((winpathname, detailsDefault) => {
  if (winpathname.includes('vasoo'))
    detailsDefault = oauthDetailsPublicVASOO
  if (winpathname.includes('zee5'))
    detailsDefault = oauthDetailsPublicZEE5

  return detailsDefault
})(window.location.pathname, oauthDetailsPublicICONIC)

const oauthDetailsPublic = {
  credentials: 'include',
  openid_uri: isdev
    ? openIdConfigDevURI
    : openIdConfigProdURI,
  client_id: params.get('client_id') || oauthDetailsPublicEnv.client_id,
  redirect_uri: params.get('redirect_uri'),
  token: params.get('token')
}

export {
  oauthDetailsPublicVASOO,
  oauthDetailsPublicICONIC,
  oauthDetailsPublicZEE5,
  oauthDetailsPublic
}

export default oauthDetailsPublic
