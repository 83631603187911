// import emailRegex from 'https://cdn.skypack.dev/email-regex';
import emailRegex from 'email-regex'

import {
  enumInputDisplayName,
  enumInputEmail,
  enumInputPassword,
  enumInputConfirmPassword,
  enumInputDateOfBirth,
  enumInputTOSAgreement
} from './enum.js'

const isPasswordValidRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$*%^&]).{8,32}$/

const validateGetDisplayNameError = (formElem, inputElem, error = '') => {
  const inputValue = inputElem.value

  if (inputValue.length < 4)
    error = 'Display name too short'

  return error
}

const validateGetEmailError = (formElem, inputElem, error = '') => {
  const inputValue = inputElem.value

  if (!emailRegex({ exact: true }).test(inputValue))
    error = 'Email is invalid'

  return error
}

const validationGetDateOfBirthError = (formElem, inputElem, error = '') => {
  const inputValue = inputElem.value

  if (!/^\d\d\d\d-\d\d-\d\d$/.test(inputValue))
    error = 'Date of Birth missing or invalid'

  return error
}

const validateGetPasswordError = (formElem, inputElem, error = '') => {
  const inputValue = inputElem.value

  if (!isPasswordValidRe.test(inputValue))
    error = ['Password is invalid. A valid password is at least 8 characters',
      'long and contains at least one of each: number, a special character, a',
      'lowercase and an uppercase letter.'].join(' ')

  return error
}

const validateGetConfirmPasswordError = (formElem, inputElem, error = '') => {
  const inputValue = inputElem.value
  const passwordInput = formElem[enumInputPassword]
  const passwordValue = passwordInput.value

  if (!inputValue)
    error = 'Confirm Password too short'

  if (inputValue !== passwordValue ||
        validateGetPasswordError(formElem, passwordInput))
    error = 'Confirm Password is wrong'

  return error
}

const validateGetTOSError = (formElem, inputElem, error = '') => {
  if (!inputElem.checked)
    error = 'Agreement to the Terms of Service is required.'

  return error
}

const validationMap = {
  [enumInputDisplayName]: validateGetDisplayNameError,
  [enumInputEmail]: validateGetEmailError,
  [enumInputPassword]: validateGetPasswordError,
  [enumInputConfirmPassword]: validateGetConfirmPasswordError,
  [enumInputDateOfBirth]: validationGetDateOfBirthError,
  [enumInputTOSAgreement]: validateGetTOSError
}

const validateInputGetError = (formElem, inputElem) => {
  const inputName = inputElem instanceof Element
        && inputElem.name

  return validationMap[inputName]
        && validationMap[inputName](formElem, inputElem)
}

export default validateInputGetError
