import formUrldecoded from 'form-urldecoded'
import { ssohandySessGet } from '@platform/sso-handy'

import isDev from './isdev.js'

const params = new window.URLSearchParams(window.location.search)

const reqUriAuthServiceLocal = 'http://localhost:8002'
const reqUriAuthServiceProd = 'https://sso.auth.iconicengine.com'
const reqUriAuthServiceDev = 'https://dev.auth.iconicengine.com'
const reqUriAuthServiceEnv = isDev
  ? reqUriAuthServiceDev
  : reqUriAuthServiceProd
const reqUriAuthService = /^local/.test(params.get('host') || params.get('state'))
  ? reqUriAuthServiceLocal
  : reqUriAuthServiceEnv

const reqUriUserinfo = `${reqUriAuthService}/userinfo`
const reqUriUserVerifyToken = `${reqUriAuthService}/user/verify`
const reqUriUserRegister = `${reqUriAuthService}/user/register`
const reqUriUserUpdate = `${reqUriAuthService}/user/update`
const reqUriValidate = `${reqUriAuthService}/user/verify`
const reqUriForgot = `${reqUriAuthService}/user/password-reset-email`
const reqUriReset = `${reqUriAuthService}/user/password-reset`
const reqUriTokens = `${reqUriAuthService}/tokens`
const reqUriDevice = `${reqUriAuthService}/auth`
const reqUriUserDeactivate = `${reqUriAuthService}/user/deactivate`

const getAccessToken = () => ssohandySessGet().access_token

const fetchNormal = (uri, opts) => fetch(uri, opts).then(async res => ({
  json: await res.json().catch(() => res.text()),
  status: res.status,
  statusText: res.statusText,
  redirected: res.redirected,
  url: res.url
})).catch(e => e)

// 2\d\d response is success
const reqValidateGET = async token => (
  fetchNormal(`${reqUriUserVerifyToken}/${token}`, {
    method: 'GET'
  }))

const reqDeactivatePOST = async token => (
  fetchNormal(`${reqUriUserDeactivate}/${token}`, {
    method: 'POST'
  }))
// ex, { sub: "3WunGKCGTVejcPdkpOx5u", display_name: "than" }
const reqUserinfoGET = () => (
  fetchNormal(`${reqUriUserinfo}/${getAccessToken()}`, {
    method: 'GET'
  }))

const reqRefreshPOST = async (client_id, refresh_token, token = getAccessToken()) => (
  fetchNormal(reqUriTokens, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...(token && {
        authorization: `Bearer ${token}`
      })
    },
    credentials: 'include',
    body: {
      refresh_token,
      client_id,
      grant_type: 'refresh_token'
    }
  }))

const reqFormPOST = async (formElem, url, token = getAccessToken()) => (
  fetchNormal(url || formElem.action, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...(token && {
        authorization: `Bearer ${token}`
      })
    },
    credentials: 'include',
    body: (() => {
      const dataForm = Object.fromEntries(new FormData(formElem))
      const dataQuery = formUrldecoded(window.location.search)

      // do NOT overwrite location.search values w/ mutable (dangerous) Form values.
      const data = { ...dataForm, ...dataQuery }

      if (data.email)
        data.username = data.email
      Array.from(formElem.querySelectorAll('input[type=checkbox]'),
        cb => data[cb.name] = cb.checked)
      Array.from(formElem.querySelectorAll('input[type=number]'),
        cb => data[cb.name] = Number(cb.value))

      return JSON.stringify(data)
    })()
  }).then(res => {
    if (res.redirected) {
      window.location.href = res.url
    }

    return res
  })
)

export {
  reqUriAuthService,
  reqUriUserRegister,
  reqUriUserinfo,
  reqUriUserUpdate,
  reqUriValidate,
  reqUriForgot,
  reqUriReset,
  reqUriTokens,
  reqUriDevice,

  reqRefreshPOST,
  reqValidateGET,
  reqUserinfoGET,
  reqFormPOST,
  reqDeactivatePOST
}
