import formUrldecoded from 'form-urldecoded'
import jwtdecode from 'jwt-decode'
import isdev from './isdev.js'
import render from './render.js'

import './gear.css'
import './provider-btn.css'
import './custom.pikaday-dark.css'

import {
  ssohandySessSet,
  ssohandySessResumeTry,
  ssohandyLoginRevokeAndClear
} from '@platform/sso-handy'

import {
  reqUriTokens,
  reqValidateGET
} from './req.js'

import {
  enumPageTypeProfile,
  // enumPageTypeProfileUpdate,
  enumPageTypeRegister,
  enumPageTypeValidate,
  enumPageTypeForgot,
  enumPageTypeReset,
  enumPageTypeLogin,
  enumPageTypeLogout,
  enumPageTypes,
  enumPageTypesReqSessActive,
  enumPageTypesReqSessInactive
} from './enum.js'

const pageParamsCreate = (page, returnpage, params) => {
  params = new window.URLSearchParams(window.location.search)
  if (returnpage) {
    params.set('returnpage', returnpage)
  }

  if (params.get('returnpage') === page) {
    params.delete('returnpage')
  }
  params = params.toString()
  return params ? `?${params}` : ''
}

const pageUrlCreate = (page, returnpage) => [
  window.location.pathname.replace(/[^/]*\/$/, page.replace(/^\//, '')),
  page === '/' ? '' : `/${pageParamsCreate(page, returnpage)}`
].join('')

export default async (renderer, oauthDetails) => {
  const params = formUrldecoded(window.location.search)
  const theme = window.matchMedia
    && window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light'
  const pagepath = window.location.pathname.split('/').slice(-2)[0]
  const page = enumPageTypes[`/${pagepath}`] || enumPageTypeRegister
  const email = params.email

  document.body.className = `app-isdev-${isdev}`

  console.log(`[...] page: ${page}`)
  const data = {
    oauthDetails,
    uriRegister: pageUrlCreate(enumPageTypeRegister),
    uriValidate: pageUrlCreate(enumPageTypeValidate),
    uriForgot: pageUrlCreate(enumPageTypeForgot),
    uriReset: pageUrlCreate(enumPageTypeReset),
    uriLogin: pageUrlCreate(enumPageTypeLogin),
    uriProfile: pageUrlCreate(enumPageTypeProfile),

    uriLogout: pageUrlCreate(enumPageTypeLogout),
    uriLoginSwitchReturn: pageUrlCreate(enumPageTypeLogout, page),
    theme,
    validation: {},
    values: {},
    params,
    page,
    email
  }

  const alertMessageErrorRes = res => renderer.alertMessageError(
    res.json && res.json.error_description || res.json)

  data.sess = await ssohandySessResumeTry(oauthDetails, (err, sess) => {
    if (err) {
      alertMessageErrorRes('session error')
    }

    if (sess) { // rerender forms with newest session data
      data.sess = sess

      render(renderer, data)
    }
  })

  if (page === enumPageTypeLogout) {
    await ssohandyLoginRevokeAndClear(oauthDetails)
    renderer.alertMessageSuccess('Logged out.')
  } else if (page === enumPageTypeValidate) {
    const res = await reqValidateGET(params.token)

    data.validationIsSuccess = /2\d\d/.test(res.status)
  } else if (data.sess.is_active && enumPageTypesReqSessInactive[page]) {
    return window.location.replace(pageUrlCreate(enumPageTypeProfile))
  } else if (!data.sess.is_active && enumPageTypesReqSessActive[page]) {
    window.location.replace(pageUrlCreate(enumPageTypeLogin, page))
  }

  render(renderer, data)

  document.body.addEventListener('form:submitted', async e => {
    const { res, form } = e.detail
    const resJSON = res.json || {}

    if (!/2\d\d/.test(res.status))
      return alertMessageErrorRes(res)

    data.submitSuccess = true

    if (form.action === reqUriTokens && resJSON.access_token) {
      const id_token = jwtdecode(resJSON.id_token)

      ssohandySessSet({
        access_token: resJSON.access_token,
        refresh_token: resJSON.refresh_token,
        expires_in: resJSON.expires_in,
        block: {
          email: id_token.email,
          email_verified: id_token.email_verified,
          display_name: id_token.display_name
        }
      })

      if (params.returnpage) { // go to 'returnpage' if one is defined
        window.location.assign(pageUrlCreate(params.returnpage))
      } else if (params.redirect) {

      } else {
        window.location.replace(pageUrlCreate(enumPageTypeProfile))
      }
    }

    return render(renderer, data)
  })
}
