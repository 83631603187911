import render, { helperElems } from './render.js'

import pikaday from 'pikaday'
import 'pikaday/css/pikaday.css'

import {
  reqFormPOST,
  reqUriUserRegister,
  reqUriUserUpdate,
  reqUriForgot,
  reqUriReset,
  reqUriDevice,
  reqUriTokens,
  reqDeactivatePOST,
  reqUserinfoGET
} from './req.js'

import {
  enumPageTypeRegister,
  enumPageTypeValidate,
  enumPageTypeForgot,
  enumPageTypeReset,
  enumPageTypeLogin,
  enumPageTypeLogout,
  enumPageTypeDevice,
  enumPageTypeProfile,
  enumPageTypeProfileUpdate,

  enumInputToken,
  enumInputDisplayName,
  enumInputEmail,
  enumInputPassword,
  enumInputConfirmPassword,
  enumInputDateOfBirth,
  enumInputClientId,
  enumInputTOSAgreement,
  enumInputUserCode
} from './enum.js'

import validate from './validate.js'

import {
  msgSuccessProfileUpdate,
  msgSuccessForgotPassword,
  msgSuccessRegistration,
  msgSuccessReset,
  msgSuccessDevice
} from './msg.js'

const {
  div,
  h3,
  p,
  a,
  span,
  label,
  input,
  form,
  button,
  img
} = helperElems

const rerender = data => render(renderPage, data)

// eslint-disable-next-line max-len
const iconBase64EyeVisibilityTrue = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAADOklEQVR4Ae3BX2jVdRjH8ffn+R5bLtOyi6hhN26u6IhnE/ROIiP8B9pNbP6J+S+iurCQoG4Ug27SupDIi60NAjeJwGC2WRJCUblwzpxrTM0g0DJDklltZ7/naWDX209BKDivFxUVFRUV/3FiCmuatyakTRHxuIe/09PVfpk7rMBUQoUgSpJeMKXS6uZtr934a2Lg+OGOeGJdi2bOtAeSbC5wN5MC/o7w3z38Wm9Xh3MbxDRWNLVUmextk22J4FI5y3YUCjrqE6xLSa9IqgdmcdP1IC5ExFeIDvcY7u1qd26ByGl189YNkvYCc4LYo9CBCfcWdwpVM9JFII2Vs5pC0hIzPQ1Ue/huk73XfbB1lJwSOdUVG87K/BjSYqGNQcwP/N2C2ZeIDcAyLIbMbK979oHQvSZ7yZ3GeXWLvr44PPAHOSRyWLN+22xgexCnJTsE1EhaK2kZaMgjOyFpq8k2AnOFfRIRRz38SjJ7LhVUu2Dh4t5zg/3jTCORQ12x4WVJuyM4ceRg23cLig1HQ4Gw1RIrhUY8fJ/JHhI8I+n7I51tZ2uLpdPADZM9H8T12mLp2/ODA0zFyEHSTqBP2DEmdXe2jc64y/dAbAeqJR1IShsjYoeH71Oonkm9XR0Tgg6gz6RdyRLTKZBDEH1CjUEsAPqZNDaWqoFuiH6DDkm7wml04lWl8Sv8K0StoD6IzyOC6Rh5RLwB/GnS/pVNm+uZpIh7BK8TkYJYC/G+mZYnSx+Hp6VMWtm8eZ5h+4Fxobc8nOkkcjg3eOq3R0ulXyP0rMmeql1Y+tHDh5OlUaHNQhdwPgrFVZNWSVpXV2wYTYUYcVfNeDk+7D3U9sX5wQGmk8jpscZFw+42CKwVagEeyTyGyhNZT5KJsOuu7KTJTgFPSmp2l2cZe8fLfvKn4YEgB3GLVjS13JeUdktqAh4ErgG/gLIgPhO86eFzhFolLQd6ssh29nS2/0AO4jasatqSHL/flJaYtBR4mJsuRabOTOXhqipmZeXCLuBF4HJk2qkUh7sPtjIVcQetWb9tZkQ0IfYIzfaM+Z8ear3KFBJ30MiZ/on5xUVnDPtG6GeI4+cGT2VUVFRUVPyP/QOiWmhUWMixOwAAAABJRU5ErkJggg=='
// eslint-disable-next-line max-len
const iconBase64EyeVisibilityFalse = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAACrklEQVR4Ae3BT2jXdRzH8efr/fluP6cpWUH/0AimM1qiGXbokHQQF5Z1CH4prU2HXRI62EE7KAQeOkhgltWav5P9sCSCqb8w6tAfZFDWcBBkCB1qCLkalW77fd/vBgoRQfv+du37eFAqlUqlUmvEPG14ok8dHXZzkt0ELGBWwNUI/8XDJxr1mjMPokWbqwMJWNb0eC5lbBDqBG7gmskgfoiIzxE19/iuUT/qtEC04LFtA4vDeRGxC8jC4/RMHiOVtnQRSFMz+Z1Z0nozbQQWevh+kx0ePjb4OwWJgh55sn/5gko6YsbDBG/nkR8k9HOS2mV6OeCuPLxhshPu+SKT7ZW0zZ1Pr07luz754OiPFJAoYPPWgSUp0yEzPZRHvsfCXjlVH7p8Yeycr1h9f0fAbqFNJm0Elgr7MCI+8vBLyaw3Zepced+6xvfnv55mDokCVnSvfd6kHR6+LyLeOlUfmul5uu+Wu+9Ze7uZtpjUC1SATHCvpHMn331nrLN7zbfAHybbGcRkZ/easxfOf8N/MQqQtBv4kmCoUa81mZVkz1ba0qhJrwOL+dtCC61mVqNeawpqwIhJ+5Il5mIUEMQI0I20iusiNAG0A8Y/5Xn4Za4L0Ql0BXHGw5mLUUTEXuBPkw71VPu7mBURHwOj/Nto4CeZ1VPt7zLsMDAtdMDDmYso6PFntj/luR0EfnJ8j7t/llm2LogDQqsAc48xM73U9OZXmbIHQrxGcNv0jL9w5v2h9yggoyBLfsLdfiN41bDjiOPN3I81c++ttKVlArsylV+stKeVydIbiC2CXyNi50zTT1OQaNGmat+NSWm/pCpwKzABjINyiOXAEmCc4E2CI8P1wXFaIObh0er25PhSU1pv0oPAHVwz7h5nr0zlXyyqZJPD9UGnVCqVSqX/mb8AQvcYtci4rC0AAAAASUVORK5CYII='

const urlToS = 'https://www.myvasoo.com/tos-privacy'
const urlPrivacyPolicy = 'https://www.myvasoo.com/privacy-policy'

const detectInputType = inputName => {
  if (inputName.includes('pass')) return 'password'
  if (inputName.includes('email')) return 'email'
  if (inputName.includes('code')) return 'number'
  return 'text'
}

const capitalize = str => str
  .toLowerCase()
  .split(/ |_/)
  .map(word => word.charAt(0).toUpperCase() + word.substring(1))
  .join(' ')

const alertMessageError = messageError => window.UIkit.notification(messageError, {
  message: messageError,
  status: 'danger',
  pos: 'top-center',
  timeout: 5000
})

const alertMessageSuccess = messageSuccess => window.UIkit.notification(messageSuccess, {
  message: messageSuccess,
  status: 'success',
  pos: 'top-center',
  timeout: 5000
})

const validateInput = (data, formElem, inputElem) => {
  const validateElems = inputElem.name === enumInputPassword
    ? [inputElem, formElem[enumInputConfirmPassword]].filter(e => e)
    : [inputElem]

  validateElems.forEach(elem => (
    // 'undefined' removes state from the input
    data.validation[elem.name] = validate(formElem, elem)
      ? undefined : true
  ))

  rerender(data)
}

const onRemoveUser = data => {
  window.UIkit.modal.confirm('Are you sure you want to delete your account?',
    {
      labels:
      {
        cancel: 'Cancel', ok: 'Delete'
      }
    }).then(async () =>
  {
    const re = await reqDeactivatePOST(await clientId(data)).then(async res =>
    { return res }).catch(e => alertMessageError(e.message))
    if(re.json.success) {
      const logoutButton = document.getElementsByClassName('logout')
      logoutButton[0].click()
    }
  }, () => {
  }).catch(err=>console.log(err))
}

const validateForm = (data, formElem) => {
  Array.from(formElem.elements).map(elem => {
    const validationMsg = validate(formElem, elem)

    if (validationMsg) alertMessageError(validationMsg)

    data.validation[elem.name] = validationMsg
      ? false : true
  })

  rerender(data)

  return Object.values(data.validation).every(v => v)
}

const clientId = data =>reqUserinfoGET(data).then(res => res.json.sub)

const onInputChange = (ev, data) => validateInput(
  data, ev.target.form, ev.target)

const onFormSubmit = (ev, data) => {
  ev.preventDefault()

  if (validateForm(data, ev.target)) {
    reqFormPOST(ev.target).then(res => {
      // event listener at 'init.js'
      document.body.dispatchEvent(new window.CustomEvent('form:submitted', {
        detail: {
          res,
          form: {
            name: ev.target.name,
            action: ev.target.action,
            method: ev.target.method
          }
        }
      }))
    }).catch(e => alertMessageError(e.message))
  }
}

const renderContentSuccess = data => (
  div('.uk-card.uk-card-default.uk-card-body', [
    h3('.uk-card-title', data.successTitle || 'Success'),
    p(data.successMessage)
  ]))

const renderContentWarning = data => (
  div('.uk-card.uk-card-default.uk-card-body.uk-label-warning', [
    h3('.uk-label-warning', data.errorTitle || 'Error'),
    p(data.errorMessage)
  ]))

const renderContentWaiting = data => (
  div('.uk-card.uk-card-default.uk-card-body', [
    h3('.uk-card-title', data.waitingTitle || 'Waiting...'),
    p(data.waitingMessage)
  ]))

const renderInputText = (data, name, placeholder) => (
  div('.uk-margin.uk-text-left', [
    label('.uk-span', { props: { for: name } }, capitalize(name)),
    div('.uk-inline.uk-width-1-1', [
      input('.uk-input.uk-form-large.uk-border-rounded', {
        id: name,
        class: {
          // to remove: data.validation[name] = undefined
          'uk-form-success': data.validation[name] === true,
          'uk-form-danger': data.validation[name] === false
        },
        props: {
          placeholder,
          type: detectInputType(name),
          name: name,
          value: data.values[name],
          autocomplete: 'on'
        }
      })
    ])
  ])
)

const renderInputPassword = (data, name, placeholder) => (
  div('.uk-margin.uk-text-left', [
    label('.uk-span', { props: { for: name } }, capitalize(name)),
    div('.uk-inline.uk-width-1-1.uk-relative', [
      input('.uk-input.uk-form-large.uk-border-rounded', {
        id: name,
        class: {
          // to remove: data.validation[name] = undefined
          'uk-form-success': data.validation[name] === true,
          'uk-form-danger': data.validation[name] === false
        },
        props: {
          placeholder,
          type: data[`${name}show_password`] ? 'text' : 'password',
          name: name,
          value: data.values[name],
          autocomplete: 'on'
        }
      }),
      button('.uk-icon-button.uk-position-center-right.uk-position-small', {
        props: {
          type: 'button',
          title: 'show password'
        },
        on: {
          click: () => {
            data[`${name}show_password`] = !data[`${name}show_password`]

            rerender(data)
          }
        }
      }, [
        img('.uk-img', {
          attrs: {
            'uk-img': '',
            'data-src': data.show_password
              ? iconBase64EyeVisibilityTrue
              : iconBase64EyeVisibilityFalse
          },
          props: {
            width: 36,
            height: 36
          }
        })
      ])
    ])
  ])
)

const renderInputDateNative = (data, name, labelprimary, labelSecond) => (
  div('.uk-margin.uk-text-left', [
    label('.uk-span', { props: { for: name } }, capitalize(labelprimary)),
    div('.uk-inline.uk-width-1-1.uk-relative', [
      input('.uk-input.uk-form-large.uk-border-rounded', {
        id: name,
        props: {
          type: 'date',
          min: data.min, // yyyy-dd-mm, '2018-01-01'
          max: data.max, // yyyy-dd-mm, '2018-01-01'
          name,
          value: data.values[name]
        },
        on: {
          click: ev => ev.srcElement.showPicker(),
          focus: ev => (
            // Failed to execute 'showPicker' on HTMLInputElement::showPicker()
            // requires a user gesture.
            false && ev.srcElement.showPicker()
          )
        }
      }),
      span('.uk-text-italic', labelSecond)
    ])
  ])
)

const renderInputDateScripted = (data, name, labelprimary, labelSecond, placeholder) => (
  div('.uk-margin.uk-text-left', [
    label('.uk-span', { props: { for: name } }, capitalize(labelprimary)),
    div('.uk-inline.uk-width-1-1.uk-relative', [
      input('.uk-input.uk-form-large.uk-border-rounded', {
        id: name,
        hook: {
          insert: vnode => {
            window.picker = new pikaday({
              field: vnode.elm,
              minDate: new Date(data.min),
              maxDate: new Date(data.max),
              theme: 'pikaday-dark',
              position: 'top left',
              // same format required by native date input
              format: 'YYYY-MM-DD',
              toString: (date, format) => format
                .replace(/YYYY/, date.getFullYear())
                .replace(/MM/, ("0" + (date.getMonth() + 1)).slice(-2))
                .replace(/DD/, ("0" + (date.getDate() + 1)).slice(-2)),
              defaultDate: (
                new Date( // set 1-year back, avoid showing disabled days
                  new Date().setFullYear(new Date(data.max).getFullYear() - 1))),
              yearRange: [
                new Date(data.min).getFullYear(),
                new Date(data.max).getFullYear()
              ],
              onSelect: function () {
                // console.log(this.getMoment().format('Do MMMM YYYY'));
              }
            })
          }
        },
        props: {
          placeholder,
          type: 'text',
          name,
          value: data.values[name],
          pattern: "\\d\\d\\d\\d-\\d\\d-\\d\\d"
        },
        on: {
          // block non-number key input. user can still paste things
          // in or use browser inspector to change value etc
          keypress: ev => (ev.key.length > 1 || /^\d|-$/.test(ev.key))
            && ev.preventDefault()
        }
      }),
      span('.uk-text-italic', labelSecond)
    ])
  ])
)

const renderInputDate = /firefox/i.test(navigator.userAgent)
  ? renderInputDateScripted
  : renderInputDateNative

const renderInputDateOfBirth = (data, name, labelprimary, labelSecondary) => (
  renderInputDate({
    ...data,
    min: new Date( // 100 years ago:'1920-01-01'
      new Date().setFullYear(new Date().getFullYear() - 100)
    ).toISOString().split('T')[0],
    max: new Date( // 13 years ago:'2010-01-01'
      new Date().setFullYear(new Date().getFullYear() - 13)
    ).toISOString().split('T')[0]
  }, name, labelprimary, labelSecondary, 'Date of Birth, YYYY-MM-DD')
)

const renderInputCheckbox = (data, name, labelprimary) => (
  div('.uk-margin.uk-text-left', (
    label('.uk-flex.uk-flex-left.uk-flex-middle', { props: { for: name } }, [
      input('.uk-input.uk-checkbox.uk-border-rounded', {
        id: name,
        props: {
          type: 'checkbox',
          name,
          value: /^true$/.test(data.values[name])
        }
      }),
      p('.uk-paragraph', {
        props: { innerHTML: labelprimary }
      })
    ])
  ))
)

const renderHidden = (data, name, value = name) => input({
  props: { type: 'hidden', name, value }
})

const renderInputButtonSubmit = (data, name) => (
  label('.uk-margin', [
    input('.uk-button.uk-button-secondary.uk-button-large.uk-border-rounded', {
      props: {
        type  : 'submit',
        name  : name,
        value : name
      }
    })
  ])
)

const renderFormRegister = data => (
  form('.uk-form.uk-form-stacked', {
    props: {
      name: 'register',
      action: reqUriUserRegister,
      method: 'post'
    },
    on: {
      input: ev => onInputChange(ev, data),
      submit: ev => onFormSubmit(ev, data)
    }
  }, [
    renderInputText(data, enumInputDisplayName),
    renderInputText(data, enumInputEmail),
    renderInputPassword(data, enumInputPassword, 'At least 8 characters'),
    renderInputDateOfBirth(data, enumInputDateOfBirth, 'Your date of birth',
      '* Your birthday will not be saved. It is only used for verification.'),
    renderInputCheckbox(data, enumInputTOSAgreement, [
      `Yes, I agree to the Vasoo <a href="${urlToS}">Terms of Service</a>`,
      `and <a href="${urlPrivacyPolicy}">Privacy Policy</a>`
    ].join('<br />')),
    renderHidden(data, enumInputClientId, data.oauthDetails.client_id),
    renderInputButtonSubmit(data, 'sign up'),
    p('.uk-paragraph', [
      a('.uk-margin.uk-text-light.uk-text-capitalize', {
        props: { href: data.uriForgot }
      }, 'forgot password?')
    ])
  ])
)

const renderFormProfileUpdate = data => (
  form('.uk-form.uk-form-stacked', {
    props: {
      name: 'profile-update',
      action: reqUriUserUpdate,
      method: 'post'
    },
    on: {
      input: ev => onInputChange(ev, data),
      submit: ev => onFormSubmit(ev, data)
    }
  }, [
    renderInputText(data, enumInputDisplayName),
    renderInputText(data, enumInputEmail),
    renderInputDateOfBirth(data, enumInputDateOfBirth, 'Your date of birth',
      '* Your birthday will not be saved. It is only used for verification.'),
    // renderInputCheckbox( data, enumInputTOSAgreement, [
    //   `Yes, I agree to the Vasoo <a href="${urlToS}">Terms of Service</a>`,
    //   `and <a href="${urlPrivacyPolicy}">Privacy Policy</a>`
    // ].join( '<br />' ) ),
    renderHidden(data, enumInputClientId, data.oauthDetails.client_id),
    p('.uk-paragraph', {
      props: {
        innerHTML: [
          'You agree to Vasoo<br />',
          '<a href=":href-tos">Terms of Service</a> and ',
          '<a href=":href-pp">Privacy Policy</a>'
        ].join('')
          .replace(/:href-tos/, urlToS)
          .replace(/:href-pp/, urlPrivacyPolicy)
      }
    }),
    renderInputButtonSubmit(data, 'update'),
    p('.uk-paragraph', [
      a('.uk-margin.uk-text-light.uk-text-capitalize', {
        props: { href: data.uriProfile }
      }, 'return to profile?')
    ])
  ])
)

const renderFormLogin = data => (
  form('.uk-form-stacked', {
    props: {
      name: 'login',
      action: reqUriTokens,
      method: 'post'
    },
    on: {
      input: ev => onInputChange(ev, data),
      submit: ev => onFormSubmit(ev, data)
    }
  }, [
    renderInputText(data, enumInputEmail),
    renderInputText(data, enumInputPassword),
    renderHidden(data, 'tenant', 'iconic'),
    renderHidden(data, 'grant_type', 'password'),
    renderHidden(data, 'scope', 'openid email profile'),
    renderHidden(data, enumInputClientId, data.oauthDetails.client_id),
    renderInputButtonSubmit(data, 'log in'),
    div('.uk-margin',
      a('.uk-text-capitalize', {
        props: { href: data.uriForgot }
      }, 'forgot password?')
    )
  ])
)

const renderButtonProviderGoogle = data => (
  button('.login-with-btn.google.uk-border-rounded', {
    props: {
      type: 'button',
      title: 'google'
    },
    on: {
      click: () => console.log('click', data)
    }
  }, span('.uk-display-inline-block', 'Log In with Google')))

const renderButtonProviderFacebook = data => (
  button('.login-with-btn.facebook.uk-border-rounded.uk-margin', {
    props: {
      type: 'button',
      title: 'facebook'
    },
    on: {
      click: () => console.log('click', data)
    }
  }, span('.uk-display-inline-block', 'Log In with Facebook'))
)

const renderFormLoginProvider = data => (
  div('.uk-margin.uk-flex.uk-flex-column.uk-flex-between', [
    renderButtonProviderGoogle(data),
    renderButtonProviderFacebook(data)
  ])
)

const renderFormLoginICONICORProvider = data => (
  div('', [
    null && renderFormLoginProvider(data),
    renderFormLogin(data)
  ])
)

const renderFormForgot = data => (
  form('.uk-form-stacked', {
    props: {
      name: 'forgot',
      action: reqUriForgot,
      method: 'post'
    },
    on: {
      input: ev => onInputChange(ev, data),
      submit: ev => onFormSubmit(ev, data)
    }
  }, [
    renderInputText(data, enumInputEmail),
    renderHidden(data, enumInputClientId, data.oauthDetails.client_id),
    renderInputButtonSubmit(data, 'send reset link')
  ])
)

const renderFormReset = data => (
  form('.uk-form-stacked', {
    props: {
      name: 'reset',
      action: reqUriReset,
      method: 'post'
    },
    on: {
      input: ev => onInputChange(ev, data),
      submit: ev => onFormSubmit(ev, data)
    }
  }, [
    renderInputPassword(data, enumInputPassword),
    renderInputPassword(data, enumInputConfirmPassword),
    renderHidden(data, enumInputToken, data.oauthDetails.token),
    renderInputButtonSubmit(data, 'reset password')
  ])
)

const renderFormDevice = data => (
  form('.uk-form-stacked', {
    props: {
      name: 'device',
      action: reqUriDevice,
      method: 'post'
    },
    on: {
      input: ev => onInputChange(ev, data),
      submit: ev => onFormSubmit(ev, data)
    }
  }, [
    div('.uk-margin.uk-text-left.uk-flex', [
      span('.uk-margin-small-right', { attrs: { 'uk-icon': 'user' } }),
      span('.uk-paragraph', data.sess.block.display_name),
      div('.uk-flex-1.uk-text-right', [
        a('.uk-text-light.uk-text-capitalize', {
          props: { href: data.uriLoginSwitchReturn }
        }, 'Change User')
      ])
    ]),
    renderInputText(data, enumInputUserCode),
    renderHidden(data, enumInputClientId, data.oauthDetails.client_id),
    renderHidden(data, 'tenant', 'iconic'),
    renderHidden(data, 'response_type', 'device_code'),
    renderInputButtonSubmit(data, 'log in device')
  ])
)

const renderProfile = data => (
  div('.uk-margin', [
    div('.uk-margin', [
      span('.uk-margin-small-right', { attrs: { 'uk-icon': 'user' } }),
      span('.uk-paragraph', data.sess.block.display_name)
    ]),
    p('.uk-paragraph', [
      a('.uk-margin.uk-text-light.uk-text-capitalize uk-margin-right logout', {
        props: { href: data.uriLogout }
      }, 'Logout'),
      a('.uk-margin.uk-text-light.uk-text-capitalize uk-margin-left remove-user', {
        on: {
          click: async () => {
            onRemoveUser(data)
          }
        }
      }, 'Remove Account')
    ])
  ])
)

const renderValidation = data => (
  typeof data.validationIsSuccess === 'boolean'
    ? (data.validationIsSuccess === true
      ? renderContentSuccess({
        successTitle: 'Welcome to Vasoo!',
        successMessage: 'Your account is now ready to use.'
      })
      : renderContentWarning({
        errorTitle: 'Oops... Something went wrong',
        errorMessage: 'Your account could not be validated.'
      }))
    : renderContentWaiting({
      waitingMessage: 'Validating.'
    }))

const pageContentMap = {
  [enumPageTypeLogin]: renderFormLoginICONICORProvider,
  [enumPageTypeLogout]: renderFormLoginICONICORProvider,
  [enumPageTypeForgot]: data => data.submitSuccess
    ? renderContentSuccess({
      successMessage: msgSuccessForgotPassword()
    }) : renderFormForgot(data),
  [enumPageTypeRegister]: data => data.submitSuccess
    ? renderContentSuccess({
      successMessage: msgSuccessRegistration()
    }) : renderFormRegister(data),
  [enumPageTypeValidate]: renderValidation,
  [enumPageTypeProfile]: renderProfile,
  [enumPageTypeProfileUpdate]:  data => data.submitSuccess
    ? renderContentSuccess({
      successMessage: msgSuccessProfileUpdate()
    }) : renderFormProfileUpdate(data),
  [enumPageTypeReset]: data => data.submitSuccess
    ? renderContentSuccess({
      successMessage: msgSuccessReset()
    }) : renderFormReset(data),
  [enumPageTypeDevice]: data => data.submitSuccess
    ? renderContentSuccess({
      successMessage: msgSuccessDevice()
    }) : renderFormDevice(data)
}

const renderPage = data => (
  div('.uk-height-1-1.uk-flex-column.uk-flex.vasoo', {
    class: {
      'uk-light': data.theme === 'dark',
      'uk-dark': data.theme === 'light'
    }
  }, [
    div('.uk-top', [
      img('.uk-img.uk-align-center.uk-margin-top.uk-margin-bottom', {
        attrs: {
          'uk-img': '',
          'data-src': '/assets/img/vasoo-blue.original.png'
        },
        props: {
          width: 188.533,
          height: 36,
          title: 'VASOO',
          alt: 'VASOO logo'
        }
      })
    ]),
    div('.uk-flex.uk-flex-column.uk-flex-middle.uk-flex-1', [
      div('.uk-height-1-1.uk-flex-column.uk-flex.uk-flex-top.form-body', [
        div('.uk-margin-auto.uk-width-large@s.uk-text-top', [
          div('.uk-flex.uk-flex-column.uk-flex-1.uk-text-center', [
            pageContentMap[data.page](data)
          ])
        ])
      ])
    ])
  ])
)

renderPage.alertMessageSuccess = alertMessageSuccess
renderPage.alertMessageError = alertMessageError

export default renderPage
