import helpers from 'hyperscript-helpers'

import {
  h,
  init,
  classModule,
  propsModule,
  styleModule,
  attributesModule,
  eventListenersModule
} from 'snabbdom'

const patch = init([
  // Init patch function with chosen modules
  classModule, // makes it easy to toggle classes
  propsModule, // for setting properties on DOM elements
  styleModule, // handles styling on elements with support for animations
  attributesModule,
  eventListenersModule // attaches event listeners
])

export const helperElems = helpers(h)

export default (oldnode => (renderer, data) => {
  const vnode = renderer(data)

  patch(oldnode, vnode)

  oldnode = vnode
})(document.getElementById('root'))
